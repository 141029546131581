import { Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

const OrderSummaryLineItem = ({ lineItem }) => {
  const configsContext = useContext(Configs);
  const lineItemAmount = (lineItem.unitAmount?.value || 0) * (lineItem.quantity || 1);

  return (
    <Fragment>
      <div className="order-description">
        <span className="order-title">
          {lineItem.name} x {lineItem.quantity}{" "}
        </span>
        <span className="cart-size">
          {formatIntegerCurrencyString(
            lineItemAmount,
            configsContext?.configs?.currency,
            configsContext?.configs?.ecommerceLocaleLanguage,
          )}
        </span>
        {lineItem.notes ? (
          <Fragment>
            <br />
            <div className="order-note">
              {lineItem.notes
                  .filter(note => note.authorType === "CUSTOMER" && note.content.trim() !== '')
                  .map((note, index) => (
                    <div key={index}>
                      {index === 0 ?
                      <div>
                        <span className="order-note-title">
                          <Text id="ORDER_ITEM_NOTE">Note: </Text>
                        </span>
                        <span>{note.content}</span>
                      </div> :
                      <div>
                        <span>{note.content}</span>
                      </div>
                      }
                    </div>
                ))
              }
            </div>
          </Fragment>
        ) : null}
      </div>
      <div style="clear: both;"></div>
      {lineItem.details?.selectedOptions?.length ? (
        <div className="order-modifiers">
          {lineItem.details?.selectedOptions.map((option, i) => {
            return (
              <span className="modifiers">
                {i ? " | " : ""}
                {option.attribute}: {option?.values?.join(", ")}
              </span>
            );
          })}
        </div>
      ) : null}
      {lineItem.details?.selectedAddons?.length ? (
        <div className="order-modifiers">
          {lineItem.details?.selectedAddons.map((addon, i) => {
            return (
              <span className="modifiers">
                {i ? " | " : ""}
                {addon.attribute}:{" "}
                {addon?.values
                  ?.map(
                    (value) =>
                      `${value.name}${
                        value.costAdjustment?.value
                          ? ` (+${formatIntegerCurrencyString(
                              value.costAdjustment.value,
                              configsContext?.configs?.currency,
                              configsContext?.configs?.ecommerceLocaleLanguage,
                            )})`
                          : ""
                      }`,
                  )
                  .join(", ")}
              </span>
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );
};

export default OrderSummaryLineItem;
