import { signal, computed } from "@preact/signals";
import { createLegacyOrderItemSignal } from "./orderItem";

export function createLegacyOrderSignal(data) {
  const notes = signal("");

  const tip = signal(0);

  const pickupChannelId = signal("");

  const items = signal(
    (data?.items || []).map((item) => {
      return createLegacyOrderItemSignal({
        ...item,
        taxes: item.taxes || data?.taxes, // attach order level taxes if not on item level
      });
    }),
  );

  const order = computed(() => {
    let discountTotal = 0;
    let feeTotal = 0;
    let taxTotal = 0;
    let subTotal = 0;

    (items?.value || []).forEach((item) => {
      const orderItem = item?.orderItem?.value;
      discountTotal -= orderItem?.discount; // order discount for SALEs is always negative; item discount is always positive (it has no sign)
      feeTotal += orderItem?.fee;
      taxTotal += (orderItem?.taxes || []).reduce((p, c) => {
        return p + c.amount / Math.pow(10, (c.amountPrecision || 2) - 2);
      }, 0);
      subTotal += orderItem?.quantity * orderItem?.unitPrice;
    });

    // round this since we did a bunch of floating point math earlier
    taxTotal = Math.round(taxTotal);

    const netTotal = subTotal + feeTotal + discountTotal + taxTotal;

    const orderObject = {
      context: {
        transactionInstruction: data?.context?.transactionInstruction || "NONE",
        source: data?.context?.source || "WEB",
        sourceApp: data?.context?.sourceApp || "co.poynt.checkout",
        channelId: data?.context?.channelId || "",
      },
      statuses: {
        fulfillmentStatus: data?.statuses?.fulfillmentStatus || "FULFILLED",
        status: data?.statuses?.status || "OPENED",
        transactionStatusSummary: data?.statuses?.transactionStatusSummary || "PENDING",
      },
      amounts: {
        discountTotal,
        feeTotal,
        taxTotal,
        subTotal,
        netTotal,
        orderAmount: netTotal,
        tipAmount: tip.value,
        transactionAmount: netTotal + tip.value,
        currency: data?.currency || "USD",
      },
      customer: data?.customer,
      notes: notes?.value,
      taxes: data?.taxes,
      items: (items?.value || []).map((item) => {
        return item?.orderItem?.value;
      }),
    };

    // if order is a pickup order, set order status to open
    if (pickupChannelId) {
      orderObject.orderShipments = [
        {
          deliveryMode: "PICKUP",
          shipmentType: "FULFILLMENT",
          status: "AWAITING_PICKUP",
        },
      ];

      orderObject.statuses.fulfillmentStatus = "NONE";

      orderObject.items.forEach((item) => {
        if (!item.pickupChannelId) {
          item.pickupChannelId = pickupChannelId;
        }
        item.fulfillmentInstruction = "PICKUP_INSTORE";
        item.status = "ORDERED";
      });
    }

    return orderObject;
  });

  return { notes, items, order, tip, pickupChannelId, isLegacy: true };
}
