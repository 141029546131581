import { Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

const OrderSummaryAmounts = ({
  subTotal,
  discountTotal,
  feeTotal,
  taxTotal,
  shippingTotal,
  tipAmount,
  total,
}) => {
  const configsContext = useContext(Configs);

  return (
    <Fragment>
      {subTotal !== total ? (
        <Fragment>
          <div className="order-subtotal">
            <span style="float:left;">
              <Text id="CHECKOUT_SUBTOTAL">Subtotal</Text>
            </span>
            <span style="float:right;">
              {formatIntegerCurrencyString(
                subTotal,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )}
            </span>
            <div style="clear:both;"></div>
          </div>
          {discountTotal ? (
            <div className="order-tax">
              <span style="float:left;">
                <Text id="CHECKOUT_DISCOUNT">Discount</Text>
              </span>
              <span style="float:right;">
                {formatIntegerCurrencyString(
                  -1 * discountTotal,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </span>
              <div style="clear:both;"></div>
            </div>
          ) : null}
          {feeTotal ? (
            <div className="order-tax">
              <span style="float:left;">
                <Text id="CHECKOUT_FEES">Fees</Text>
              </span>
              <span style="float:right;">
                {formatIntegerCurrencyString(
                  feeTotal,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </span>
              <div style="clear:both;"></div>
            </div>
          ) : null}
          {taxTotal ? (
            <div className="order-tax">
              <span style="float:left;">
                <Text id="CHECKOUT_TAX">Tax</Text>
              </span>
              <span style="float:right;">
                {formatIntegerCurrencyString(
                  taxTotal,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </span>
              <div style="clear:both;"></div>
            </div>
          ) : null}
          {shippingTotal ? (
            <div className="order-tax">
              <span style="float:left;">
                <Text id="CHECKOUT_SHIPPING">Shipping</Text>
              </span>
              <span style="float:right;">
                {formatIntegerCurrencyString(
                  shippingTotal,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </span>
              <div style="clear:both;"></div>
            </div>
          ) : null}
          {tipAmount ? (
            <div className="order-tax">
              <span style="float:left;">
                <Text id="CHECKOUT_TIP">Tip</Text>
              </span>
              <span style="float:right;">
                {formatIntegerCurrencyString(
                  tipAmount,
                  configsContext?.configs?.currency,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                )}
              </span>
              <div style="clear:both;"></div>
            </div>
          ) : null}
          <div class="order-summary-divider"></div>
        </Fragment>
      ) : null}
      <div className="order-total">
        <span style="float:left">
          <Text id="CHECKOUT_TOTAL">Total</Text>
        </span>
        <span style="float:right;">
          {formatIntegerCurrencyString(
            total,
            configsContext?.configs?.currency,
            configsContext?.configs?.ecommerceLocaleLanguage,
          )}
        </span>
        <div style="clear:both;"></div>
      </div>
    </Fragment>
  );
};

export default OrderSummaryAmounts;
