import { Fragment } from "preact";
import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

const LegacyOrderSummaryItem = ({ item }) => {
  const configsContext = useContext(Configs);
  const lineItemAmount = (item.unitPrice || 0) * (item.quantity || 1);

  return (
    <Fragment>
      <div className="order-description">
        <span className="order-title">
          {item.name} x {item.quantity}{" "}
        </span>
        <span className="cart-size">
          {formatIntegerCurrencyString(
            lineItemAmount,
            configsContext?.configs?.currency,
            configsContext?.configs?.ecommerceLocaleLanguage,
          )}
        </span>
      </div>
      <div style="clear: both;"></div>
      {item.selectedVariants?.[0]?.selectableVariations?.length ? (
        <div className="order-modifiers">
          {item.selectedVariants?.[0]?.selectableVariations.map((variation, i) => {
            return (
              <span className="modifiers">
                {i ? " | " : ""}
                {variation.values.map((value) => value.name).join(", ")}
              </span>
            );
          })}
        </div>
      ) : null}
    </Fragment>
  );
};

export default LegacyOrderSummaryItem;
