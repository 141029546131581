import useScript from "./use-script";

const getTcclUrl = (env?: string) => {
  switch (env) {
    case "development":
    case "dev-private":
    case "dev":
    case "ci":
    case "st":
    case "ote":
    case "test":
      return "https://traffic-clients.dev-secureserver.net/assets/js/tccl.min.js";
    case "production":
    case "prod":
      return "https://img1.wsimg.com/traffic-assets/js/tccl.min.js";
    default:
      return "";
  }
};

function useC2Analytics(env?: string, businessId?: string, payLink?: any) {
  const TCCL_URL = getTcclUrl(env);

  const status = useScript(TCCL_URL);

  const scriptReady = status === "ready";

  // @ts-ignore
  window._expDataLayer = window._expDataLayer || [];
  // @ts-ignore
  const tcclInstance = window?._expDataLayer;

  const networkReady = scriptReady && !!tcclInstance;

  const trackCustomEvent = (
    {
      tcclEvent,
    }: {
      tcclEvent: string;
    },
    customProperties: any,
  ) => {
    try {
      if (env !== "production") {
        if (!networkReady) {
          console.log("Traffic event queued before first load", { tcclEvent });
        } else {
          console.log("Traffic event queued", { tcclEvent });
        }
      }

      tcclInstance.push({
        schema: "add_event",
        version: "v1",
        data: {
          type: "click",
          eid: tcclEvent,
        },
        ...(customProperties ? { custom_properties: customProperties } : {}),
      });
    } catch (err) {
      if (env !== "production") {
        console.log("Traffic event error", { tcclEvent, err });
      }
    }
  };

  return {
    trackCustomEvent,
  };
}

export default useC2Analytics;
