import { createContext } from "preact";
import { useState } from "preact/hooks";

import { DEFAULT_LOCALE } from "./formatters/formatting-options";

import enUS from "../../locales/en-us.json";
import frCA from "../../locales/fr-ca.json";

export const I18N_MESSAGE_LIST = {
  "en-us": enUS,
  "fr-ca": frCA,
};

export const DEFAULT_CONFIGS = {
  businessId: "",
  currency: "USD",
  domain: "",
  businessWebsite: "",
  businessPhone: "",
  ecommerceCheckoutButtonColor: "#2544b7",
  ecommerceBusinessName: "",
  ecommerceCheckoutLogoUrl: "",
  ecommerceLocaleLanguage: DEFAULT_LOCALE,
  supportApplePay: false,
  supportGooglePay: false,
  messages: I18N_MESSAGE_LIST[DEFAULT_LOCALE],
};

export const Configs = createContext({
  configs: {},
  setConfigs: () => {},
  setConfig: () => {},
});

export const ConfigsProvider = ({ children }) => {
  // the value that will be given to the context
  const [configs, _setConfigs] = useState(DEFAULT_CONFIGS);

  // override setConfigs function so we can do other fun stuff
  function setConfigs(data) {
    if (data.ecommerceLocaleLanguage) {
      // i18n settings
      data.messages = I18N_MESSAGE_LIST[data.ecommerceLocaleLanguage] || I18N_MESSAGE_LIST[DEFAULT_LOCALE];
    }

    _setConfigs({
      ...configs,
      ...data,
    });
  }

  function setConfig(name, value) {
    setConfigs({
      [name]: value,
    });
  }

  return (
    <Configs.Provider
      value={{
        configs,
        setConfigs,
        setConfig,
      }}
    >
      {children}
    </Configs.Provider>
  );
};
